import { styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const Stepper: React.FC<any> = ({
  steps,
  step,
  setStep,
  responsiveWidth = 600,
}) => {
  const [maxStep, setMaxStep] = React.useState(0);

  useEffect(() => {
    setMaxStep((prev) => Math.max(prev, step));
  }, [step]);

  return (
    <Root $responsiveWidth={responsiveWidth}>
      {steps.map((el: string, index: number) => (
        <Item
          responsiveWidth={responsiveWidth}
          activeStep={maxStep}
          key={index}
          label={el}
          index={index}
          setStep={setStep}
        />
      ))}
    </Root>
  );
};

export default Stepper;

const Root = styled("div")<{ $responsiveWidth: number }>(
  ({ theme, $responsiveWidth }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    justifyContent: "center",
    userSelect: "none",
    background: theme.palette.primary.main,
    color: "white",
    padding: theme.spacing(2),
    gap: "50px",
    [`@media screen and (max-width: ${$responsiveWidth}px)`]: {
      flexDirection: "row",
      gap: "10px",
      alignItems: "start",
    },
  })
);

const Item: React.FC<any> = ({
  label,
  index,
  activeStep,
  setStep,
  responsiveWidth,
}) => {
  const checked = activeStep > index;
  const clickable = activeStep >= index;

  const onClick = () => {
    if (clickable) {
      setStep(index);
    }
  };

  return (
    <ItemRoot
      $responsiveWidth={responsiveWidth}
      $clickable={clickable}
      onClick={onClick}
    >
      <Round $checked={checked}>
        {checked ? <CheckRoundedIcon /> : index + 1}
        <Line
          onClick={(e) => e.stopPropagation()}
          className="line-separator"
          $responsiveWidth={responsiveWidth}
        />
      </Round>
      <Typography>{label}</Typography>
    </ItemRoot>
  );
};

const ItemRoot = styled("div")<{
  $clickable: boolean;
  $responsiveWidth: number;
}>(({ $clickable, $responsiveWidth }) => ({
  display: "flex",
  "*": {
    cursor: $clickable ? "pointer" : "default",
  },
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  "&:last-of-type .line-separator": {
    display: "none",
  },
  [`@media screen and (max-width: ${$responsiveWidth}px)`]: {
    flexDirection: "column",
    gap: "5px",
  },
}));

const Line = styled("div")<{ $responsiveWidth: number }>(
  ({ $responsiveWidth }) => ({
    height: "30px",
    borderLeft: "1px solid white",
    position: "absolute",
    bottom: "-40px",
    cursor: "default",
    [`@media screen and (max-width: ${$responsiveWidth}px)`]: {
      display: "none",
    },
  })
);

const Round = styled("div")<{ $checked: boolean }>(
  ({ theme, $checked }: any) => ({
    position: "relative",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: $checked ? theme.palette.green[500] : "white",
    cursor: $checked ? "pointer" : "default",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: $checked ? "white" : theme.palette.primary.main,
  })
);
