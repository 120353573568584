import { Box, Button, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CustomTabs from "../components/CustomTabs";
import LogoLoader from "../components/LogoLoader";
import Page from "../components/Page";
import ProjectsCareerList from "../components/projects/ProjectsCareerList";
import ProjectTrainingList from "../components/projects/ProjectTrainingList";
import useProjects from "../hooks/useProjects";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { shallowEqual, useSelector } from "react-redux";

const tabs = ["career", "training"];

const BackButton = styled(Button)`
  border-radius: 100px;
  font-size: 12px;
  font-weight: normal;
  padding: 5px 15px;
  height: 55px;
`;

const Root = styled("div")(({ theme }: any) => ({
  background: theme.palette.collabBlue,
  borderRadius: "27px",
  padding: "38px 24px 48px 24px",
}));

const ProjectsPage: React.FC = () => {
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const { t } = useTranslation("common");
  const [type, setType] = useState(0);
  const { id } = useParams();
  console.log("id", id);
  const personId = tokenData?.person_id;
  console.log("personId", personId);
  const navigate = useNavigate();
  const context = useSelector((state: any) => state.auth.context);
  const { loading, careers, trainings, person } = useProjects(id ?? personId);

  const disableProjectsTrainings = useSelector(
    (state: any) => state.home?.paramjson?.disableProjectsTrainings
  );

  const backToProfile = () =>
    navigate(`/app/profile/${id ? `${id}/overview` : "overview"}`);

  const handleChange = (val: number) => {
    setType(val);
  };

  return (
    <Page title={t("filters.projects")} padding sx={{ marginTop: "52px" }}>
      <LogoLoader open={loading} />

      {!loading && (
        <Root>
          {id && (
            <BackButton variant="outlined" onClick={backToProfile}>
              <ArrowBackRoundedIcon sx={{ mr: 1 }} />
              <Typography variant="body2">Retourner sur le profil</Typography>
            </BackButton>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              paddingTop: 10,
            }}
          >
            <Typography variant="h3">
              {id ? `Projets de ${person?.fullName}` : t("projects.my_title")}
            </Typography>
          </div>
          {!disableProjectsTrainings && (
            <CustomTabs
              value={type}
              onChange={handleChange}
              tabs={tabs.map((tab: string) => t(`projects.${tab}`))}
            />
          )}
          <Box sx={{ mt: !disableProjectsTrainings ? 0 : 2 }}>
            {type === 0 || disableProjectsTrainings ? (
              <ProjectsCareerList careerItems={careers} context={context} />
            ) : (
              <ProjectTrainingList items={trainings} />
            )}
          </Box>
        </Root>
      )}
    </Page>
  );
};

export default ProjectsPage;
