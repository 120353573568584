import { getNumericDate } from "../getNumericDate";

const profileFormatter = (data: any) => {
  const anonName = getAnonName(data.reference);
  const context = localStorage.getItem("context");
  const person = data.candidate ?? data;
  const anonymous =
    (context === "third_party" && String(data?.status ?? "") !== "30") ||
    (!person?.firstName && !person?.lastName);
  const res: any = {
    id: person.id,
    createdAt: person.createdAt,
    fullName: anonymous
      ? data.reference ?? "John Doe"
      : `${person.firstName} ${person.lastName}`,
    firstName: anonymous ? anonName[0] : person.firstName,
    lastName: anonymous ? anonName[1] : person.lastName,
    email: person.email ?? null,
    gender: person.gender ?? "N",
    hasEmail: Boolean(person.email) ?? false,
    hobbies: person.hobbies ?? [],
    languages: person.languages ?? [],
    onboarding: person.onboarding ?? 0,
    otherData: person.otherData ?? {},
    actualWork: person.actualWork ?? "",
    files: person.files ?? [],
    isFollow: person.isFollow ?? false,
    onboardingTest: person.onboardingTest ?? [],
    completenessPercent: person.completenessPercent ?? 0,
    driverLicenses: person.driverLicenses ?? [],
    anonymization: data?.anonymization,
    anonymizationDate: handleAnonymizationDates(data?.anonymization?.dates),
    sharedBy: handleRecruitersList(data?.recruiterProfiles),
    anonymous,
    data: person.data,
    simplified: person?.data?.expectedData?.simplified ?? false,
    profilePicture: person?.image?.contentUrl,
    description: person.description,
    thirdParties: person?.profiles?.map((el: any) => ({
      fullName: el?.thirdPartyInfos?.fullName,
      folders: el?.thirdPartyInfos?.campaignsTimesAffected,
      company: el?.thirdPartyInfos?.company,
      id: el?.thirdPartyInfos?.thirdPartyId,
    })),
    ...(person?.image?.contentUrl && { image: person.image }),
    address: person.addressInfo?.full_address,
    status: person.status,
    offersCount: person.offersCount,
  };

  return res;
};

const handleRecruitersList = (recruiterProfiles: any[]) => {
  return (recruiterProfiles ?? [])
    .map((profile: any) => {
      try {
        const {
          recruiter: { person },
        } = profile;
        return {
          ...person,
          fullName: person.fullName,
          initials: person.firstName.charAt(0) + person.lastName.charAt(0),
          company: person.company?.name ?? "",
        };
      } catch {
        return null;
      }
    })
    .filter((el) => el);
};

const handleAnonymizationDates = (dates: any) => {
  if (!dates) return null;
  const keys = Object.keys(dates);
  const values = Object.values(dates);

  if (!values.some((value) => value)) return null;

  const key = keys.reduce((a, b) =>
    new Date(dates[a]) > new Date(dates[b]) ? a : b
  );

  return {
    key,
    value: getNumericDate(dates[key]),
  };
};

export const getAnonName = (data: any) => {
  try {
    const midpoint = Math.floor(data.length / 2);
    return [data.substring(0, midpoint), data.substring(midpoint)];
  } catch (err) {
    return ["John", "Doe"];
  }
};

export default profileFormatter;
