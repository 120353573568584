import { Box, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DatePicker from "../common/DatePicker";

const ControlledDatePicker: React.FC<{
  control: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  rules?: any;
  label?: string;
  minDate?: any;
  maxDate?: any;
  style?: any;
  variant?: "filled" | "standard" | "outlined" | undefined;
}> = ({
  control,
  name,
  required,
  disabled,
  rules,
  label,
  minDate,
  maxDate,
  style,
  variant = "outlined",
}) => {
  const { t }: any = useTranslation("common");

  const getError = (errors: any) => {
    const path = name;
    const pathParts: any = path.split(".");

    let errorExists = errors;
    for (let part of pathParts) {
      // If the part refers to an array element (e.g., '0'), convert to an index
      if (part.match(/^\d+$/)) {
        part = parseInt(part, 10);
      }

      if (errorExists && errorExists[part] !== undefined) {
        errorExists = errorExists[part];
      } else {
        errorExists = undefined;
        break;
      }
    }
    return errorExists;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: "errors.required" },
        ...rules,
      }}
      render={({
        field: { onChange, value, onBlur },
        formState: { errors },
      }) => (
        <Box>
          {console.log(errors, name)}
          <DatePicker
            sx={style}
            minDate={minDate}
            maxDate={maxDate}
            date={value}
            label={label}
            error={Boolean(getError(errors))}
            variant={variant}
            sendDataToParent={(e: any) => onChange(e)}
            onBlur={onBlur}
            disabled={disabled}
          />
          {Boolean(getError(errors)) && (
            <Typography variant="body2" component="small" color="error">
              {getError(errors) ? t(getError(errors)?.message) : " "}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};

export default ControlledDatePicker;
