import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useFetch from "../../hooks/useFetch";
import peopleAPI from "../../_api/_people";
import { shallowEqual, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectShortcutItem from "./ProjectShortcutItem";

const LastProjectCard = ({ personId, context, canEditPeople }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [projectsList, setProjectsList] = useState<any>([]);
  const tokenData = useSelector((state: any) => state.user, shallowEqual);

  const { data, loading } = useFetch(
    peopleAPI.getById(`${personId ? personId : tokenData?.person_id}/projects`)
  );

  useEffect(() => {
    setProjectsList(data?.["hydra:member"]?.[0]?.projectCareers);
  }, [data]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Card>
        <CardHeader
          sx={{ pt: 1 }}
          title={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2">Projets récents</Typography>
              {(((context === "advisor" || context === "cre") &&
                canEditPeople) ||
                projectsList?.length > 0) && (
                <IconButton onClick={handleMenuOpen}>
                  <MoreHorizIcon />
                </IconButton>
              )}
            </Box>
          }
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => navigate(`/app/profile/${personId}/projects`)}
          >
            {projectsList?.length > 0
              ? "Voir tout les projets"
              : "Ajouter un projet"}
          </MenuItem>
        </Menu>
        <CardContent
          sx={{
            paddingInline: 2,
            paddingTop: 0,
            paddingBottom: 0,
            display: "flex",
            width: "100%",
            mt: 1,
            flexWrap: "wrap",
          }}
        >
          {!loading &&
            (projectsList?.length > 0 ? (
              projectsList
                ?.slice(0, 3)
                .map((project: any) => (
                  <ProjectShortcutItem item={project} key={project?.id} />
                ))
            ) : (
              <Typography variant="body2" sx={{ opacity: "0.7", ml: 1 }}>
                Aucun projets pour le moment
              </Typography>
            ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default LastProjectCard;
