import { Card, CardContent, Typography, Chip, styled } from "@mui/material";
import { Box, useMediaQuery, useTheme } from "@mui/system";
import React from "react";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LongText from "../LongText";
import jobsAPI from "../../_api/_jobsAPI";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";

const JobItem = styled(Card)<{ jobId: any }>(({ theme, jobId }: any) => ({
  transition: "all 0.25s ease-in-out",
  borderRadius: theme.shape.borderRadius10,
  "&:hover": jobId
    ? {
        transform: "scale(1.015)",
        boxShadow: theme.customShadows.default,
        cursor: "pointer",
      }
    : {},
}));

const TextColor = styled(Typography)(({ theme }: any) => ({
  textTransform: "uppercase",
  color: theme.palette.secondary.light,
}));

const TextCompany = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.light,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const CandidateOfferItem: React.FC<{
  job: any;
  index: number;
}> = ({ job, index }) => {
  const theme = useTheme();

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy");
  };

  const getLocation = (location: string) => {
    if (!isSmUp) {
      return location.split(",")?.[0];
    }
    return location;
  };

  // Effectuer l'appel à l'API

  const handleClick = async (id: string) => {
    try {
      const res = await axios.get(jobsAPI.getById(id));

      if (res.data) {
        window.open(`/app/job/${id}`, "_blank");
      }
    } catch (err) {
      toast.error("Cette offre n'existe plus");
      console.log(err);
    }
  };

  return (
    <>
      <JobItem
        jobId={job.job_id}
        onClick={() => handleClick(job?.job_id)}
        key={job?.job_id}
      >
        <CardContent sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={job?.logo_company ?? "/static/icons/portfolio.svg"}
            width="60px"
            alt="logo entreprise"
            style={{ padding: job?.logo_company ? "0px" : "9px" }}
          />
          <Box
            sx={{
              ml: { xs: 2, md: 3 },
              mt: 0,
              width: "100%",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <LongText
                  maxChar={45}
                  text={job?.job_label}
                  variant="body1"
                  fontWeight="bold"
                />
                <Typography sx={{ mr: 1, ml: 1 }}>|</Typography>
                <LongText maxChar={50} text={job?.work_label} variant="body1" />
              </Box>
              <Box sx={{ marginLeft: "auto" }}>
                Répondu le{" "}
                {formatDate(
                  job?.date?.date ? job?.date?.date : job?.date_applied.date
                )}
              </Box>
            </Box>
            <TextCompany>{job?.job_company}</TextCompany>
            <Box display="flex" flexWrap="wrap" sx={{ mt: 1 }}>
              {job?.location?.[0] && (
                <Chip
                  sx={{ mr: 1 }}
                  label={
                    <TextColor
                      variant="body2"
                      alignItems="center"
                      display="flex"
                    >
                      <RoomOutlinedIcon sx={{ fontSize: "15px", mr: 0.5 }} />
                      <Box sx={{ wordWrap: "break-word" }}>
                        {getLocation(job?.location?.[0])}
                      </Box>
                    </TextColor>
                  }
                />
              )}
              {job?.contract_type && (
                <Chip
                  sx={{ ml: { xs: 0, md: 1 } }}
                  label={
                    <TextColor
                      variant="body2"
                      alignItems="center"
                      display="flex"
                    >
                      <DescriptionOutlinedIcon
                        sx={{ fontSize: "15px", mr: 0.5 }}
                      />
                      <Box sx={{ wordWrap: "break-word" }}>
                        {job?.contract_type}
                      </Box>
                    </TextColor>
                  }
                />
              )}
            </Box>

            {/* <Grid item xs={12} sm={6}>
                      <Box
                        display="flex"
                        sx={{ justifyContent: { sm: "right" } }}
                      >
                        {job.work?.id &&
                          (isSmUp ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                goToWork(job.work_id);
                              }}
                              sx={{ fontSize: "12px" }}
                            >
                              {t("job_suggest.see_work")}
                            </Button>
                          ) : (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                m: 2,
                              }}
                            >
                              <IconButton
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  goToWork(job.work_id);
                                }}
                              >
                                <WorkIcon context="neutral" />
                              </IconButton>
                            </Box>
                          ))}
                      </Box>
                    </Grid> */}
          </Box>
        </CardContent>
      </JobItem>
    </>
  );
};

export default CandidateOfferItem;
