import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import CandidateOfferItem from "./CandidateOfferItem";

const modalStyle: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "98vh",
  overflow: "auto",
  borderRadius: "6px",
  width: { xs: "95%", sm: "85%", md: "900px" },
  height: "85%",
};
const CandidateOfferModal = ({ open, jobList, setOpen }: any) => {
  return (
    <div>
      <Modal open={open}>
        <Card sx={{ ...modalStyle }}>
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{ mt: -2, mr: -2 }}
              >
                <HighlightOff />
              </IconButton>
            </Box>
            <Box sx={{ paddingInline: "16px" }}>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Offres répondues
              </Typography>
              <Stack
                spacing={2}
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {jobList
                  .sort(
                    (a: any, b: any) =>
                      new Date(
                        b.date.date ? b.date.date : b.date_applied.date
                      ).getTime() -
                      new Date(
                        a.date.date ? a.date.date : a.date_applied.date
                      ).getTime()
                  )
                  .map((job: any, index: number) => (
                    <CandidateOfferItem job={job} index={index} key={job?.id} />
                  ))}
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};

export default CandidateOfferModal;
