import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useFetch from "../../../../hooks/useFetch";
import { Api } from "../../../../_api/Api.class";
import { useTranslation } from "react-i18next";
import { styled, Tooltip, Typography } from "@mui/material";

const ExperienceAutocomplete: React.FC<any> = ({
  experience,
  onChange,
  value,
  onBlur,
  error,
  label,
}) => {
  const handleValue = (newValue: any) => {
    onChange?.(newValue);
  };

  return (
    <>
      <SuggestsAutocomplete
        value={value}
        label={label}
        suggestions={experience?.rome ?? []}
        getValue={handleValue}
        onBlur={onBlur}
        error={Boolean(error)}
      />
      <Typography color="error" variant="body2" component="small">
        {error ?? " "}
      </Typography>
    </>
  );
};

export default ExperienceAutocomplete;

const SuggestsAutocomplete: React.FC<{
  suggestions: any;
  getValue: (v: any) => any;
  value: any;
  onBlur: () => void;
  error?: boolean;
  label?: string;
}> = ({ suggestions = [], getValue, value, onBlur, error, label }) => {
  const { t } = useTranslation("common");
  const [needle, setNeedle] = React.useState("");
  const [term, setTerm] = React.useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] =
    React.useState<SuggestOptionType[]>(suggestions);
  const [api] = useState(new Api(`${"works"}/autocomplete`));

  const { data, loading } = useFetch(
    api.get({
      needle,
      limit: 10,
    }),
    true
  );

  useEffect(() => {
    if (loading) setOptions([]);
  }, [loading]);

  const handleClose = () => {
    setOpen(false);
    if (!value) setOptions(suggestions);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (data?.["hydra:member"]) {
      setOptions(data?.["hydra:member"]);

      setOpen(true);
    }
  }, [data]);

  useEffect(() => {
    setOptions(suggestions);
  }, [suggestions]);

  const handleTerm = (e: any) => {
    setTerm(e.target.value);
  };

  useEffect(() => {
    const to = setTimeout(() => {
      console.log("Search for ", term);
      if (term) setNeedle(term);
    }, 500);

    return () => {
      clearTimeout(to);
    };
  }, [term]);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          getValue({
            label: newValue,
          });
        } else if (newValue && newValue.label) {
          // Create a new value from the user input
          getValue({
            label: newValue.label,
            ...newValue,
          });
        } else {
          getValue(null);
        }
      }}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      filterOptions={(x) => x}
      selectOnFocus
      handleHomeEndKeys
      loading={loading}
      loadingText={t("autocomplete.loading")}
      id="autocomplete-rome"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;

        const random = Math.random().toString(36).substring(7);

        return (
          <li key={key + random} {...optionProps}>
            {option.label}
          </li>
        );
      }}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <Root>
          <TextField
            {...params}
            error={error}
            onBlur={onBlur}
            label={label ?? "ROME"}
            onChange={handleTerm}
          />
          {!label && (
            <Tooltip
              title="Vous devez sélectionner une dénomination ROME pour que votre
            expérience soit correctement classée et comprise. Cela nous permet
            de mieux adapter les suggestions d'opportunités à votre profil et de
            garantir que votre parcours professionnel soit bien interprété."
            >
              <Round>?</Round>
            </Tooltip>
          )}
        </Root>
      )}
    />
  );
};

interface SuggestOptionType {
  label: string;
  id: string;
}

const Root = styled("div")`
  position: relative;
`;

const Round = styled("div")(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: "50%",
  padding: "2px",
  width: "20px",
  height: "20px",
  position: "absolute",
  top: "0px",
  right: "-10px",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none",
}));
