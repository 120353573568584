import React, { useEffect, useState } from "react";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { TextField, Button, Box, Stack, Typography } from "@mui/material";
import ExperienceAutocomplete from "./ExperienceAutocomplete";
import ControlledDatePicker from "../../../forms/ControlledDatePicker";
import { useTranslation } from "react-i18next";
import LocationAutocomplete from "./LocationAutocomplete";
import Stepper from "../Stepper";
import SButton from "../../../common/buttons/SButton";
import ExperienceRating from "../../../experiences/ExperienceRating";
import { useSelector } from "react-redux";
import FullfilmentRating from "../../../common/FullfilmentRating";

interface ExperiencesFormProps {
  nextStep: () => void;
  prevStep: () => void;
}

const ExperiencesForm: React.FC<ExperiencesFormProps> = ({
  nextStep,
  prevStep,
}) => {
  const hideFulfillmentLevel = useSelector(
    (state: any) => state.home?.paramjson?.hideFulfillmentLevel
  );
  const { t } = useTranslation("common");
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    trigger, // Access trigger for validation
    formState: { errors },
  } = useFormContext();

  // if can add exp then add "append" to the list of destructured values
  const { fields, remove } = useFieldArray({
    control,
    name: "experiences",
  });

  const [currentExperienceIndex, setCurrentExperienceIndex] = useState(0);

  const [exp, setExp] = useState(null);

  useEffect(() => {
    const experiences = watch("experiences") || [];
    const currentExperience = experiences[currentExperienceIndex];
    setExp(currentExperience);
    if (currentExperience) {
      Object.keys(currentExperience).forEach((fieldKey) => {
        setValue(
          `experiences.${currentExperienceIndex}.${fieldKey}`,
          currentExperience[fieldKey]
        );
      });
    }
  }, [currentExperienceIndex, watch, setValue]);

  const validateCurrentExperience = async () => {
    // Validate the current experience fields
    return await trigger(`experiences.${currentExperienceIndex}`);
  };

  const nextExperience = async () => {
    const isValid = await validateCurrentExperience();
    if (isValid) {
      setCurrentExperienceIndex((prev) => prev + 1);
    }
  };

  const prevExperience = () => setCurrentExperienceIndex((prev) => prev - 1);

  // const addExperience = () => {
  //   append({
  //     label: "",
  //     company: "",
  //     location: "",
  //     description: "",
  //     dateStart: "",
  //     dateEnd: "",
  //     rome: null,
  //   });
  //   setCurrentExperienceIndex(fields.length);
  // };

  const removeCurrentExperience = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (userConfirmed) {
      remove(currentExperienceIndex);
      setCurrentExperienceIndex((prev) => Math.max(prev - 1, 0));
    } else {
      // User canceled
      console.log("Action canceled.");
    }
  };

  const isLastExperience = currentExperienceIndex === fields.length - 1;
  const isFirstExperience = currentExperienceIndex === 0;

  const [minEndDate, setMinEndDate] = useState<any>(null);
  useEffect(() => {
    const subscription = watch((value: any) => {
      setMinEndDate(value.dateStart);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const handleNextStep = async () => {
    const isValid = await validateCurrentExperience();
    if (isValid) {
      nextStep();
    }
  };

  console.log('getValues("work")', getValues("work"));

  return (
    <Box>
      <Stepper
        maxSteps={fields.length}
        activeStep={currentExperienceIndex}
        handleNext={nextExperience}
        handleBack={prevExperience}
        onNext={handleNextStep}
        onPrev={prevStep}
      />

      {fields.length > 0 ? (
        <Box>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label={t("form.post")}
              {...register(`experiences.${currentExperienceIndex}.label`, {
                required: "Label is required",
              })}
              error={Boolean(
                (errors.experiences as any)?.[currentExperienceIndex]?.label
              )}
              helperText={
                (errors.experiences as any)?.[currentExperienceIndex]?.label
                  ?.message
              }
              margin="normal"
            />

            <Controller
              name={`experiences.${currentExperienceIndex}.work`}
              rules={{
                required: "This field is required",
              }}
              render={({ field: { onChange, value, onBlur } }) => (
                <div>
                  <ExperienceAutocomplete
                    value={value}
                    experience={exp}
                    onChange={onChange}
                    error={
                      (errors as any)?.experiences?.[currentExperienceIndex]
                        ?.work?.message
                    }
                    onBlur={() => {
                      onBlur();
                    }}
                  />
                </div>
              )}
            />

            {getValues(`experiences.${currentExperienceIndex}.work`) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Box>
                  <Typography>{t("work.evaluate")}</Typography>
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, value },
                      formState: { errors },
                    }) => (
                      <ExperienceRating onChange={onChange} value={value} />
                    )}
                    name={`experiences.${currentExperienceIndex}.personalData.level`}
                  />
                </Box>

                <Box sx={{ m: 2 }} />

                {!hideFulfillmentLevel && (
                  <Box>
                    <Controller
                      control={control}
                      name={`experiences.${currentExperienceIndex}.personalData.fulfillment`}
                      rules={{
                        validate: {
                          required: (value) =>
                            Number(value) > 0 ? true : "errors.required",
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        formState: { errors },
                      }) => (
                        <Box>
                          <Typography
                            color={
                              (errors?.personalData as any)?.fulfillment
                                ? "error"
                                : "default"
                            }
                          >
                            {t("work.eval_fulfillment")}
                          </Typography>
                          <FullfilmentRating
                            onChange={onChange}
                            value={value}
                          />
                          <Typography variant="body2" color="error">
                            {(errors?.personalData as any)?.fulfillment
                              ? t("errors.required")
                              : " "}
                          </Typography>
                        </Box>
                      )}
                    />
                  </Box>
                )}
              </Box>
            )}

            <TextField
              fullWidth
              label={t("form.company")}
              {...register(`experiences.${currentExperienceIndex}.company`, {
                required: t("errors.required"),
              })}
              error={
                (errors.experiences as any)?.[currentExperienceIndex]?.company
              }
              helperText={
                (errors.experiences as any)?.[currentExperienceIndex]?.company
                  ?.message
              }
            />

            <Controller
              name={`experiences.${currentExperienceIndex}.location`}
              render={({ field: { onChange, value, onBlur } }) => (
                <>
                  <LocationAutocomplete
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                </>
              )}
            />
          </Stack>

          <Box display="flex" flexWrap="wrap" sx={{ mt: 2.5 }}>
            <ControlledDatePicker
              control={control}
              name={`experiences.${currentExperienceIndex}.dateStart`}
              required
              maxDate={Date.now()}
              label={t("form.start_date") + " *"}
            />
            <Box sx={{ ml: { xs: 1, sm: 2 } }}>
              <ControlledDatePicker
                control={control}
                name={`experiences.${currentExperienceIndex}.dateEnd`}
                label={"Date de fin"}
                minDate={minEndDate}
              />
            </Box>
          </Box>
          <TextField
            fullWidth
            label="Description"
            multiline
            rows={3}
            {...register(`experiences.${currentExperienceIndex}.description`)}
            margin="normal"
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={removeCurrentExperience}
              disabled={fields.length === 1}
            >
              Supprimer l'experience
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <p>No experiences added yet.</p>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          mt: 2,
          gap: "20px",
          margin: "10px",
        }}
      >
        <SButton
          variant="outlined"
          onClick={isFirstExperience ? prevStep : prevExperience}
        >
          {t(`btn.${isFirstExperience ? "back" : "previous_exp"}`)}
        </SButton>
        <SButton
          variant="contained"
          onClick={isLastExperience ? nextStep : nextExperience}
        >
          {t(`btn.${isLastExperience ? "next" : "next_exp"}`)}
        </SButton>
      </Box>
    </Box>
  );
};

export default ExperiencesForm;
