import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import { useState } from "react";
import CandidateOfferModal from "./CandidateOfferModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const CandidateOfferCard = ({ person }: any) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleViewOffers = () => {
    setOpen(true);
    handleMenuClose();
  };
  const jobList = person?.data?.offers
    ? Object.values(person.data.offers).filter(
        (offer: any) =>
          (offer.date && offer.date.date) ||
          (offer.date_applied && offer.date_applied.date)
      )
    : [];
  return (
    <div>
      <Card>
        <CardHeader
          sx={{ pt: 2 }}
          title={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2">Offres répondues</Typography>
              {jobList?.length > 0 && (
                <IconButton onClick={handleMenuOpen}>
                  <MoreHorizIcon />
                </IconButton>
              )}
            </Box>
          }
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleViewOffers}>Voir les offres</MenuItem>
        </Menu>
        <CardContent
          sx={{
            paddingRight: 10,
            paddingTop: "5px",
            paddingBottom: "5px",
            display: "flex",
            width: "100%",
            mt: 1,
          }}
        >
          {jobList?.length ? (
            <Typography variant="subtitle1">{jobList?.length}</Typography>
          ) : (
            <Typography variant="body2" sx={{ opacity: "0.7" }}>
              Aucune offre pour le moment
            </Typography>
          )}
        </CardContent>
      </Card>
      <CandidateOfferModal open={open} jobList={jobList} setOpen={setOpen} />
    </div>
  );
};

export default CandidateOfferCard;
