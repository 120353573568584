import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  Box,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  styled,
} from "@mui/material";
import LocationAutocomplete from "./LocationAutocomplete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useTranslation } from "react-i18next";
import SButton from "../../../common/buttons/SButton";

interface InfosFormProps {
  nextStep: () => void;
  cancel: () => void;
}

const InfosForm: React.FC<InfosFormProps> = ({ nextStep, cancel }) => {
  const { t } = useTranslation("common");
  const { control } = useFormContext();

  const { fields, remove } = useFieldArray({
    control,
    name: "hobbies",
  });

  return (
    <Box sx={{ mt: 4 }}>
      <Stack spacing={2}>
        <Controller
          name={`postalAddress`}
          render={({ field: { onChange, value, onBlur } }) => (
            <>
              <LocationAutocomplete
                width="100%"
                label={t("form.address")}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            </>
          )}
        />

        <Stack spacing={1}>
          <Typography>{t("profilepage.hobbies")}</Typography>
          <HobbiesGrid>
            {fields.map((item, index) => (
              <div key={item.id}>
                <Controller
                  control={control}
                  name={`hobbies[${index}]`}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={"remove"}
                            onClick={() => remove(index)}
                          >
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
              </div>
            ))}
          </HobbiesGrid>
        </Stack>
      </Stack>

      <Box
        sx={{
          display: "flex",
          mt: 2,
          gap: "20px",
          margin: "10px",
        }}
      >
        <SButton variant="outlined" onClick={cancel}>
          {t("btn.cancel")}
        </SButton>
        <SButton variant="contained" onClick={nextStep}>
          {t("btn.next")}
        </SButton>
      </Box>
    </Box>
  );
};

export default InfosForm;

const HobbiesGrid = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;
