import React, { useContext, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import InfosForm from "./InfosForm";
import ExperiencesForm from "./ExperiencesForm";
import EducationForm from "./EducationForm";
import { styled, Typography } from "@mui/material";
import Stepper from "../../../common/Stepper";
import { toastError } from "../../../../_utils/toastError";
import axios from "axios";
import { ProfileContext } from "../../../../contexts/ProfileContext";

type FormData = {
  firstName: string;
  lastName: string;
  gender: string;
  age: number;
  birthday: string;
  description: string;
  driverLicenses: string[];
  postalAddress: string;
  socials: string[];
  phone: string;
  email: string;
  hobbies: string[];
  experiences: {
    label: string;
    company: string;
    location: string;
    description: string;
    dateStart: string;
    dateEnd: string;
    rome: string | null;
    work: any;
  }[];
  education: {
    degree: string;
    company: string;
    yearStart: string;
    yearEnd: string;
  }[];
  skills: string[];
  languages: string[];
  personalData: {
    fulfillment: number;
    level: number;
  };
};

const MultiStepForm: React.FC<any> = ({ data, close }) => {
  const methods = useForm<FormData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "",
      age: undefined,
      birthday: "",
      description: "",
      driverLicenses: [],
      postalAddress: "",
      socials: [],
      phone: "",
      email: "",
      hobbies: [],
      experiences: [],
      education: [],
      skills: [],
      languages: [],
      personalData: {
        fulfillment: 0,
        level: 2,
      },
      ...data,
    },
    mode: "onBlur",
  });

  const { peopleData, setPeopleData, reloadCareerItems } =
    useContext(ProfileContext);

  const [step, setStep] = useState(0);

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);
  const [loading, setLoading] = useState(false);

  const onSubmit = methods.handleSubmit(async (data) => {
    setLoading(true);
    try {
      const formatted = { ...data };
      formatted.experiences = formatted.experiences.map((exp) => ({
        ...exp,
        companyData: { name: exp.company },
      }));

      formatted.education = formatted.education.map((edu) => ({
        ...edu,
        companyData: { name: edu.company },
      }));

      const { education, hobbies, email, experiences } = formatted;
      console.log("Formatted Data:", {
        education,
        hobbies,
        email,
        experiences,
      });

      const calls: any[] = [];

      const careerItems = axios.post("career_items/multiple", {
        data: [
          ...experiences.map((exp: any) => ({
            ...formatExp(exp),
            person: `/api/people/${peopleData.id}`,
          })),
          ...education.map((edu: any) => ({
            ...formatEdu(edu),
            person: `/api/people/${peopleData.id}`,
          })),
        ],
      });

      calls.push(careerItems);

      calls.push(
        axios.put(`people/${peopleData.id}`, {
          hobbies: [...(peopleData.hobbies ?? []), ...hobbies],
        })
      );

      const callsKeys = ["careerItems", "hobbies"];

      const responses = await Promise.allSettled(calls);

      const responsesObject: { [key: string]: any } = {};
      responses.forEach((response, index) => {
        responsesObject[callsKeys[index]] = {
          status: response.status,
          ...(response.status === "fulfilled" && { data: response.value.data }),
        };
      });

      if (responsesObject.careerItems.status === "fulfilled") {
        reloadCareerItems();
      }

      if (responsesObject.hobbies.status === "fulfilled") {
        setPeopleData((prev: any) => ({
          ...prev,
          hobbies: [...prev.hobbies, ...hobbies],
        }));
      }
    } catch (err) {
      console.error(err);
      toastError();
    } finally {
      setLoading(false);
      close();
    }
  });

  const steps = ["Infos", "Experiences", "Education"];
  const formSteps = [
    <InfosForm nextStep={nextStep} cancel={close} />,
    <ExperiencesForm nextStep={nextStep} prevStep={prevStep} />,
    <EducationForm prevStep={prevStep} onSubmit={onSubmit} loading={loading} />,
  ];

  return (
    <FormProvider {...methods}>
      <Root>
        <Stepper steps={steps} step={step} setStep={setStep} />
        <Form>
          <Typography textAlign="center" variant="h3">
            {steps[step]}
          </Typography>
          {formSteps[step]}
        </Form>
      </Root>
    </FormProvider>
  );
};

export default MultiStepForm;

// const CStepper = styled(Stepper)`
//   padding: 0;
// `;

const Form = styled("form")`
  padding: 12px;
`;

const Root = styled("div")`
  width: 100%;
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const formatExp = (exp: any) => ({
  work: exp.work?.uri,
  location: exp.location,
  description: exp.description,
  dateStart: exp.dateStart,
  company: exp.company,
  label: exp.label,
  ongoing: !exp.dateEnd,
  ...(exp.dateEnd && { dateEnd: exp.dateEnd }),
  type: "experience",
  personalData: {
    fulfillment: exp.personalData?.fulfillment,
    level: exp.personalData?.level,
  },
});

const formatEdu = (exp: any) => ({
  work: exp.work?.uri,
  diplomaTitle: exp.diplomaTitle,
  location: exp.location,
  description: exp.description,
  dateStart: exp.dateStart,
  company: exp.company,
  label: exp.label,
  dateEnd: exp.dateEnd,
  diplomaLevel: 1,
  type: "training",
  ...(exp.validityDate && { validityDate: exp.validityDate }),
});
