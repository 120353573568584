import {
  Card,
  CircularProgress,
  IconButton,
  Modal,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { modalStyle } from "../../../_utils/modalStyle";
import { HighlightOff } from "@mui/icons-material";
import axios from "axios";
import UploadResumeForm from "./UploadResumeForm";
import { useSelector } from "react-redux";
import { toastError } from "../../../_utils/toastError";
import { useTranslation } from "react-i18next";

const parseAPI = `${process.env.REACT_APP_PARSER_URL}/api/v1/resume/parse`;

const UploadResumeModal: React.FC<any> = ({ open, close, file }) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const token = useSelector((state: any) => state.user.token_pdf);

  useEffect(() => {
    setLoading(false);
    setData(null);
  }, [open]);

  const handleFile = async (file: any) => {
    if (!file || loading) return;
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("token", token);
    formData.append("apiUrl", process.env.REACT_APP_BASEURL_W_API! + "api");

    try {
      const response = await axios.post(parseAPI, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      });
      const formattedData = handleData(response.data.data);
      setData(formattedData);
    } catch (error) {
      console.error(error);
      close();
      toastError();
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleFile(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Modal open={open}>
      <Card sx={{ ...modalStyle, maxWidth: "600px" }}>
        <Content>
          {data && (
            <CloseButton
              aria-label="close"
              onClick={close}
              sx={{ mt: -2, mr: -2 }}
            >
              <HighlightOff />
            </CloseButton>
          )}

          {loading && (
            <Root>
              <p>{t("resume_parser.loading")}</p>
              <CircularProgress />
            </Root>
          )}

          {data && !loading && <UploadResumeForm data={data} close={close} />}
        </Content>
      </Card>
    </Modal>
  );
};

export default UploadResumeModal;

const Content = styled("div")`
  position: relative;
`;

const Root = styled("div")`
  width: 100%;
  min-height: 180px;
  padding-top: 30px;
  padding-bottom: 30px;
  gap: 20px;
  p {
    max-width: 70ch;
    text-align: center;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const handleData = (data: any) => {
  const copy = { ...data };

  const normalizeDate = (date: string) => {
    try {
      if (date) return new Date(date);
      return null;
    } catch {
      return null;
    }
  };

  copy.experiences = copy.experiences.map((exp: any) => ({
    ...exp,
    work: exp.rome?.[0],
    dateStart: normalizeDate(exp.dateStart),
    dateEnd: normalizeDate(exp.dateEnd),
    personalData: {
      fulfillment: 3,
      level: 3,
    },
  }));

  copy.education = copy.education.map((edu: any) => ({
    ...edu,
    dateStart: normalizeDate(edu.dateStart),
    dateEnd: normalizeDate(edu.dateEnd),
    diplomaTitle: edu.label,
    work: null,
  }));

  return copy;
};

// const mockData = {
//   firstName: "Charlène",
//   lastName: "Pihéry",
//   fullName: "Charlène Pihéry",
//   gender: "",
//   age: 21,
//   birthday: "",
//   description: "",
//   driverLicenses: ["Permis B"],
//   postalAddress: {
//     id: "postcode.17616461",
//     type: "Feature",
//     place_type: ["postcode"],
//     relevance: 0.518519,
//     properties: {
//       mapbox_id: "dXJuOm1ieHBsYzpBUXpPVFE",
//     },
//     text_fr: "35700",
//     place_name_fr: "35700, Rennes, Ille-et-Vilaine, France",
//     text: "35700",
//     place_name: "35700, Rennes, Ille-et-Vilaine, France",
//     matching_text: "35700",
//     matching_place_name: "35700, Rennes, Ille-et-Vilaine, France",
//     bbox: [-1.681944, 48.110114, -1.624359, 48.154989],
//     center: [-1.632355, 48.131207],
//     geometry: {
//       type: "Point",
//       coordinates: [-1.632355, 48.131207],
//     },
//     context: [
//       {
//         id: "place.202917965",
//         mapbox_id: "dXJuOm1ieHBsYzpEQmhJVFE",
//         wikidata: "Q647",
//         text_fr: "Rennes",
//         language_fr: "fr",
//         text: "Rennes",
//         language: "fr",
//       },
//       {
//         id: "region.66637",
//         mapbox_id: "dXJuOm1ieHBsYzpBUVJO",
//         wikidata: "Q12549",
//         short_code: "FR-35",
//         text_fr: "Ille-et-Vilaine",
//         language_fr: "fr",
//         text: "Ille-et-Vilaine",
//         language: "fr",
//       },
//       {
//         id: "country.8781",
//         mapbox_id: "dXJuOm1ieHBsYzpJazA",
//         wikidata: "Q142",
//         short_code: "fr",
//         text_fr: "France",
//         language_fr: "fr",
//         text: "France",
//         language: "fr",
//       },
//     ],
//   },
//   socials: [],
//   phone: "06 71 81 68 44",
//   email: "charlenepihery@gmail.com",
//   hobbies: ["Cinéma", "Voyages", "Lecture", "Sport"],
//   experiences: [
//     {
//       label: "Chargée de recrutement (Stage)",
//       company: "ADECCO",
//       location: {
//         id: "place.191670349",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpDMnlvVFE",
//           wikidata: "Q127775",
//         },
//         text_fr: "Ploërmel",
//         language_fr: "fr",
//         place_name_fr: "Ploërmel, Morbihan, France",
//         text: "Ploërmel",
//         language: "fr",
//         place_name: "Ploërmel, Morbihan, France",
//         bbox: [-2.440059, 47.865954, -2.313762, 47.967466],
//         center: [-2.39864, 47.93166],
//         geometry: {
//           type: "Point",
//           coordinates: [-2.39864, 47.93166],
//         },
//         context: [
//           {
//             id: "region.222285",
//             mapbox_id: "dXJuOm1ieHBsYzpBMlJO",
//             wikidata: "Q12642",
//             short_code: "FR-56",
//             text_fr: "Morbihan",
//             language_fr: "fr",
//             text: "Morbihan",
//             language: "fr",
//           },
//           {
//             id: "country.8781",
//             mapbox_id: "dXJuOm1ieHBsYzpJazA",
//             wikidata: "Q142",
//             short_code: "fr",
//             text_fr: "France",
//             language_fr: "fr",
//             text: "France",
//             language: "fr",
//           },
//         ],
//       },
//       description:
//         "Analyse du besoin client / prospect, plans d'actions / prospection / fidélisation",
//       dateStart: "2018-07-02",
//       dateEnd: "2018-08-31",
//       rome: [
//         {
//           id: "bf7653fb-7e4c-4779-a51f-c59137d2d326",
//           uri: "/api/works/bf7653fb-7e4c-4779-a51f-c59137d2d326",
//           label: "Développement des ressources humaines",
//         },
//         {
//           id: "bf7653fb-7e4c-4779-a51f-c59137d2d326",
//           uri: "/api/works/bf7653fb-7e4c-4779-a51f-c59137d2d326",
//           label: "Développement des ressources humaines",
//         },
//         {
//           id: "bf7653fb-7e4c-4779-a51f-c59137d2d326",
//           uri: "/api/works/bf7653fb-7e4c-4779-a51f-c59137d2d326",
//           label: "Développement des ressources humaines",
//         },
//         {
//           id: "bf7653fb-7e4c-4779-a51f-c59137d2d326",
//           uri: "/api/works/bf7653fb-7e4c-4779-a51f-c59137d2d326",
//           label: "Développement des ressources humaines",
//         },
//       ],
//     },
//     {
//       label: "Chargée d'accueil (CDD)",
//       company: "CRÉDIT MUTUEL",
//       location: {
//         id: "place.199510093",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpDK1JJVFE",
//           wikidata: "Q127808",
//         },
//         text_fr: "Questembert",
//         language_fr: "fr",
//         place_name_fr: "Questembert, Morbihan, France",
//         text: "Questembert",
//         language: "fr",
//         place_name: "Questembert, Morbihan, France",
//         bbox: [-2.502158, 47.61608, -2.36738, 47.712547],
//         center: [-2.453111, 47.660404],
//         geometry: {
//           type: "Point",
//           coordinates: [-2.453111, 47.660404],
//         },
//         context: [
//           {
//             id: "region.222285",
//             mapbox_id: "dXJuOm1ieHBsYzpBMlJO",
//             wikidata: "Q12642",
//             short_code: "FR-56",
//             text_fr: "Morbihan",
//             language_fr: "fr",
//             text: "Morbihan",
//             language: "fr",
//           },
//           {
//             id: "country.8781",
//             mapbox_id: "dXJuOm1ieHBsYzpJazA",
//             wikidata: "Q142",
//             short_code: "fr",
//             text_fr: "France",
//             language_fr: "fr",
//             text: "France",
//             language: "fr",
//           },
//         ],
//       },
//       description:
//         "Orientation des clients en agence, gérer les appels entrants, gestion des agendas et moyens de paiement",
//       dateStart: "2017-07-11",
//       dateEnd: "2017-08-19",
//       rome: [
//         {
//           id: "27ae12f4-cbe0-4fcb-bf01-58a86c1d1e1d",
//           uri: "/api/works/27ae12f4-cbe0-4fcb-bf01-58a86c1d1e1d",
//           label: "Accueil et renseignements",
//         },
//         {
//           id: "27ae12f4-cbe0-4fcb-bf01-58a86c1d1e1d",
//           uri: "/api/works/27ae12f4-cbe0-4fcb-bf01-58a86c1d1e1d",
//           label: "Accueil et renseignements",
//         },
//         {
//           id: "e242597e-0b07-4cff-af2b-46280f91b32e",
//           uri: "/api/works/e242597e-0b07-4cff-af2b-46280f91b32e",
//           label: "Conseil en emploi et insertion socioprofessionnelle",
//         },
//         {
//           id: "27ae12f4-cbe0-4fcb-bf01-58a86c1d1e1d",
//           uri: "/api/works/27ae12f4-cbe0-4fcb-bf01-58a86c1d1e1d",
//           label: "Accueil et renseignements",
//         },
//       ],
//     },
//     {
//       label: "Chargée de projet événementiel (Stage)",
//       company: "SOCIÉTÉ DE LA RIVIÈRE SAINT-CHARLES",
//       location: {
//         id: "place.61491239",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpBNnBJSnc",
//           wikidata: "Q2145",
//         },
//         text_fr: "Québec",
//         language_fr: "fr",
//         place_name_fr: "Québec, Québec, Canada",
//         text: "Québec",
//         language: "fr",
//         place_name: "Québec, Québec, Canada",
//         bbox: [-71.549443, 46.73076, -71.133593, 46.981938],
//         center: [-71.20278, 46.812187],
//         geometry: {
//           type: "Point",
//           coordinates: [-71.20278, 46.812187],
//         },
//         context: [
//           {
//             id: "region.9255",
//             mapbox_id: "dXJuOm1ieHBsYzpKQ2M",
//             wikidata: "Q176",
//             short_code: "CA-QC",
//             text_fr: "Québec",
//             language_fr: "fr",
//             text: "Québec",
//             language: "fr",
//           },
//           {
//             id: "country.8743",
//             mapbox_id: "dXJuOm1ieHBsYzpJaWM",
//             wikidata: "Q16",
//             short_code: "ca",
//             text_fr: "Canada",
//             language_fr: "fr",
//             text: "Canada",
//             language: "fr",
//           },
//         ],
//       },
//       description:
//         "Développement de la communication, mise à jour et suivi du site internet",
//       dateStart: "2017-04-03",
//       dateEnd: "2017-05-31",
//       rome: [
//         {
//           id: "021730c2-7ba0-4077-93b3-3b2b82e5e8c5",
//           uri: "/api/works/021730c2-7ba0-4077-93b3-3b2b82e5e8c5",
//           label: "Organisation d'évènementiel",
//         },
//         {
//           id: "021730c2-7ba0-4077-93b3-3b2b82e5e8c5",
//           uri: "/api/works/021730c2-7ba0-4077-93b3-3b2b82e5e8c5",
//           label: "Organisation d'évènementiel",
//         },
//         {
//           id: "021730c2-7ba0-4077-93b3-3b2b82e5e8c5",
//           uri: "/api/works/021730c2-7ba0-4077-93b3-3b2b82e5e8c5",
//           label: "Organisation d'évènementiel",
//         },
//         {
//           id: "021730c2-7ba0-4077-93b3-3b2b82e5e8c5",
//           uri: "/api/works/021730c2-7ba0-4077-93b3-3b2b82e5e8c5",
//           label: "Organisation d'évènementiel",
//         },
//       ],
//     },
//     {
//       label: "Hôtesse de caisse (CDD)",
//       company: "SUPER U",
//       location: {
//         id: "place.112093261",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpCcTVvVFE",
//           wikidata: "Q71500",
//         },
//         text_fr: "Josselin",
//         language_fr: "fr",
//         place_name_fr: "Josselin, Morbihan, France",
//         text: "Josselin",
//         language: "fr",
//         place_name: "Josselin, Morbihan, France",
//         bbox: [-2.575394, 47.942561, -2.528003, 47.966955],
//         center: [-2.547656, 47.953663],
//         geometry: {
//           type: "Point",
//           coordinates: [-2.547656, 47.953663],
//         },
//         context: [
//           {
//             id: "region.222285",
//             mapbox_id: "dXJuOm1ieHBsYzpBMlJO",
//             wikidata: "Q12642",
//             short_code: "FR-56",
//             text_fr: "Morbihan",
//             language_fr: "fr",
//             text: "Morbihan",
//             language: "fr",
//           },
//           {
//             id: "country.8781",
//             mapbox_id: "dXJuOm1ieHBsYzpJazA",
//             wikidata: "Q142",
//             short_code: "fr",
//             text_fr: "France",
//             language_fr: "fr",
//             text: "France",
//             language: "fr",
//           },
//         ],
//       },
//       description: "",
//       dateStart: "2016-07-05",
//       dateEnd: "2016-09-03",
//       rome: [
//         {
//           id: "f3bbf7f2-2cff-48d3-b7eb-9ba5d598a071",
//           uri: "/api/works/f3bbf7f2-2cff-48d3-b7eb-9ba5d598a071",
//           label: "Personnel de caisse",
//         },
//         {
//           id: "f3bbf7f2-2cff-48d3-b7eb-9ba5d598a071",
//           uri: "/api/works/f3bbf7f2-2cff-48d3-b7eb-9ba5d598a071",
//           label: "Personnel de caisse",
//         },
//         {
//           id: "f16fed27-d1c0-42a7-bd32-6010e2287ec9",
//           uri: "/api/works/f16fed27-d1c0-42a7-bd32-6010e2287ec9",
//           label: "Encadrement du personnel de caisses",
//         },
//         {
//           id: "f16fed27-d1c0-42a7-bd32-6010e2287ec9",
//           uri: "/api/works/f16fed27-d1c0-42a7-bd32-6010e2287ec9",
//           label: "Encadrement du personnel de caisses",
//         },
//       ],
//     },
//     {
//       label: "Vendeuse (Stage)",
//       company: "ETAM",
//       location: {
//         id: "place.267954253",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpEL2lvVFE",
//           wikidata: "Q6593",
//         },
//         text_fr: "Vannes",
//         language_fr: "fr",
//         place_name_fr: "Vannes, Morbihan, France",
//         text: "Vannes",
//         language: "fr",
//         place_name: "Vannes, Morbihan, France",
//         bbox: [-2.815246, 47.619472, -2.681801, 47.694537],
//         center: [-2.756731, 47.65694],
//         geometry: {
//           type: "Point",
//           coordinates: [-2.756731, 47.65694],
//         },
//         context: [
//           {
//             id: "region.222285",
//             mapbox_id: "dXJuOm1ieHBsYzpBMlJO",
//             wikidata: "Q12642",
//             short_code: "FR-56",
//             text_fr: "Morbihan",
//             language_fr: "fr",
//             text: "Morbihan",
//             language: "fr",
//           },
//           {
//             id: "country.8781",
//             mapbox_id: "dXJuOm1ieHBsYzpJazA",
//             wikidata: "Q142",
//             short_code: "fr",
//             text_fr: "France",
//             language_fr: "fr",
//             text: "France",
//             language: "fr",
//           },
//         ],
//       },
//       description: "",
//       dateStart: "2016-01-01",
//       dateEnd: "2016-01-31",
//       rome: [
//         {
//           id: "6b4beb29-70ea-44b0-8a68-1a9b5cc5b25b",
//           uri: "/api/works/6b4beb29-70ea-44b0-8a68-1a9b5cc5b25b",
//           label: "Vente en articles de sport et loisirs",
//         },
//         {
//           id: "b227b05e-0170-47a5-adb1-cd04a42b4df3",
//           uri: "/api/works/b227b05e-0170-47a5-adb1-cd04a42b4df3",
//           label: "Accueil et services bancaires",
//         },
//         {
//           id: "9dfde578-3f6e-4f01-8a0b-69f424944a50",
//           uri: "/api/works/9dfde578-3f6e-4f01-8a0b-69f424944a50",
//           label: "Vente en habillement et accessoires de la personne",
//         },
//       ],
//     },
//     {
//       label: "Serveuse restaurant (CDD)",
//       company: "RELAIS DU MAQUIS",
//       location: {
//         id: "place.230221901",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpEYmpvVFE",
//           wikidata: "Q648073",
//         },
//         text_fr: "Saint-Marcel",
//         language_fr: "fr",
//         place_name_fr: "Saint-Marcel, Eure, France",
//         text: "Saint-Marcel",
//         language: "fr",
//         place_name: "Saint-Marcel, Eure, France",
//         bbox: [1.406425, 49.071751, 1.472513, 49.113726],
//         center: [1.445518, 49.09603],
//         geometry: {
//           type: "Point",
//           coordinates: [1.445518, 49.09603],
//         },
//         context: [
//           {
//             id: "region.861261",
//             mapbox_id: "dXJuOm1ieHBsYzpEU1JO",
//             wikidata: "Q3372",
//             short_code: "FR-27",
//             text_fr: "Eure",
//             language_fr: "fr",
//             text: "Eure",
//             language: "fr",
//           },
//           {
//             id: "country.8781",
//             mapbox_id: "dXJuOm1ieHBsYzpJazA",
//             wikidata: "Q142",
//             short_code: "fr",
//             text_fr: "France",
//             language_fr: "fr",
//             text: "France",
//             language: "fr",
//           },
//         ],
//       },
//       description: "",
//       dateStart: "2015-01-01",
//       dateEnd: "2017-12-31",
//       rome: [
//         {
//           id: "4fd554c0-e0ae-43c4-a88a-eee585ecf073",
//           uri: "/api/works/4fd554c0-e0ae-43c4-a88a-eee585ecf073",
//           label: "Service en restauration",
//         },
//         {
//           id: "4fd554c0-e0ae-43c4-a88a-eee585ecf073",
//           uri: "/api/works/4fd554c0-e0ae-43c4-a88a-eee585ecf073",
//           label: "Service en restauration",
//         },
//         {
//           id: "4fd554c0-e0ae-43c4-a88a-eee585ecf073",
//           uri: "/api/works/4fd554c0-e0ae-43c4-a88a-eee585ecf073",
//           label: "Service en restauration",
//         },
//         {
//           id: "4fd554c0-e0ae-43c4-a88a-eee585ecf073",
//           uri: "/api/works/4fd554c0-e0ae-43c4-a88a-eee585ecf073",
//           label: "Service en restauration",
//         },
//       ],
//     },
//   ],
//   education: [
//     {
//       label: "Master Marketing Vente",
//       company: "IGR IAE Rennes",
//       location: {
//         id: "place.202917965",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpEQmhJVFE",
//           wikidata: "Q647",
//         },
//         text_fr: "Rennes",
//         language_fr: "fr",
//         place_name_fr: "Rennes, Ille-et-Vilaine, France",
//         text: "Rennes",
//         language: "fr",
//         place_name: "Rennes, Ille-et-Vilaine, France",
//         bbox: [-1.752528, 48.07688, -1.624359, 48.154989],
//         center: [-1.681558, 48.113247],
//         geometry: {
//           type: "Point",
//           coordinates: [-1.681558, 48.113247],
//         },
//         context: [
//           {
//             id: "region.66637",
//             mapbox_id: "dXJuOm1ieHBsYzpBUVJO",
//             wikidata: "Q12549",
//             short_code: "FR-35",
//             text_fr: "Ille-et-Vilaine",
//             language_fr: "fr",
//             text: "Ille-et-Vilaine",
//             language: "fr",
//           },
//           {
//             id: "country.8781",
//             mapbox_id: "dXJuOm1ieHBsYzpJazA",
//             wikidata: "Q142",
//             short_code: "fr",
//             text_fr: "France",
//             language_fr: "fr",
//             text: "France",
//             language: "fr",
//           },
//         ],
//       },
//       description: "",
//       dateStart: "2018-01-01",
//       dateEnd: "2020-12-31",
//       diplomaTitle: "Master Marketing Vente",
//     },
//     {
//       label: "Licence 3 Sciences de Gestion parcours Management",
//       company: "Institut d'Administration des Entreprises Amiens",
//       location: {
//         id: "place.4229197",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpRSWhO",
//           wikidata: "Q41604",
//         },
//         text_fr: "Amiens",
//         language_fr: "fr",
//         place_name_fr: "Amiens, Somme, France",
//         text: "Amiens",
//         language: "fr",
//         place_name: "Amiens, Somme, France",
//         bbox: [2.223572, 49.846856, 2.345797, 49.950577],
//         center: [2.300174, 49.894585],
//         geometry: {
//           type: "Point",
//           coordinates: [2.300174, 49.894585],
//         },
//         context: [
//           {
//             id: "region.402509",
//             mapbox_id: "dXJuOm1ieHBsYzpCaVJO",
//             wikidata: "Q12770",
//             short_code: "FR-80",
//             text_fr: "Somme",
//             language_fr: "fr",
//             text: "Somme",
//             language: "fr",
//           },
//           {
//             id: "country.8781",
//             mapbox_id: "dXJuOm1ieHBsYzpJazA",
//             wikidata: "Q142",
//             short_code: "fr",
//             text_fr: "France",
//             language_fr: "fr",
//             text: "France",
//             language: "fr",
//           },
//         ],
//       },
//       description:
//         "Matières principales étudiées : management, marketing, informatique Semestre en Erasmus avec Wroclaw University of Economics (Pologne)",
//       dateStart: "2017-01-01",
//       dateEnd: "2018-12-31",
//       diplomaTitle: "Licence 3 Sciences de Gestion parcours Management",
//     },
//     {
//       label: "DUT Techniques de Commercialisation",
//       company: "Institut Universitaire de Technologie Vannes",
//       location: {
//         id: "place.267954253",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpEL2lvVFE",
//           wikidata: "Q6593",
//         },
//         text_fr: "Vannes",
//         language_fr: "fr",
//         place_name_fr: "Vannes, Morbihan, France",
//         text: "Vannes",
//         language: "fr",
//         place_name: "Vannes, Morbihan, France",
//         bbox: [-2.815246, 47.619472, -2.681801, 47.694537],
//         center: [-2.756731, 47.65694],
//         geometry: {
//           type: "Point",
//           coordinates: [-2.756731, 47.65694],
//         },
//         context: [
//           {
//             id: "region.222285",
//             mapbox_id: "dXJuOm1ieHBsYzpBMlJO",
//             wikidata: "Q12642",
//             short_code: "FR-56",
//             text_fr: "Morbihan",
//             language_fr: "fr",
//             text: "Morbihan",
//             language: "fr",
//           },
//           {
//             id: "country.8781",
//             mapbox_id: "dXJuOm1ieHBsYzpJazA",
//             wikidata: "Q142",
//             short_code: "fr",
//             text_fr: "France",
//             language_fr: "fr",
//             text: "France",
//             language: "fr",
//           },
//         ],
//       },
//       description:
//         "Matières principales étudiées : commerce international, stratégie, négociation",
//       dateStart: "2015-01-01",
//       dateEnd: "2017-12-31",
//       diplomaTitle: "DUT Techniques de Commercialisation",
//     },
//     {
//       label:
//         "Baccalauréat Sciences et Technologies du Management et de la Gestion",
//       company: "Lycée La Mennais Ploërmel",
//       location: {
//         id: "place.191670349",
//         type: "Feature",
//         place_type: ["place"],
//         relevance: 1,
//         properties: {
//           mapbox_id: "dXJuOm1ieHBsYzpDMnlvVFE",
//           wikidata: "Q127775",
//         },
//         text_fr: "Ploërmel",
//         language_fr: "fr",
//         place_name_fr: "Ploërmel, Morbihan, France",
//         text: "Ploërmel",
//         language: "fr",
//         place_name: "Ploërmel, Morbihan, France",
//         bbox: [-2.440059, 47.865954, -2.313762, 47.967466],
//         center: [-2.39864, 47.93166],
//         geometry: {
//           type: "Point",
//           coordinates: [-2.39864, 47.93166],
//         },
//         context: [
//           {
//             id: "region.222285",
//             mapbox_id: "dXJuOm1ieHBsYzpBMlJO",
//             wikidata: "Q12642",
//             short_code: "FR-56",
//             text_fr: "Morbihan",
//             language_fr: "fr",
//             text: "Morbihan",
//             language: "fr",
//           },
//           {
//             id: "country.8781",
//             mapbox_id: "dXJuOm1ieHBsYzpJazA",
//             wikidata: "Q142",
//             short_code: "fr",
//             text_fr: "France",
//             language_fr: "fr",
//             text: "France",
//             language: "fr",
//           },
//         ],
//       },
//       description: "",
//       dateStart: "2012-01-01",
//       dateEnd: "2015-12-31",
//       diplomaTitle:
//         "Baccalauréat Sciences et Technologies du Management et de la Gestion",
//     },
//   ],
//   skills: [
//     "Gimp",
//     "Wordpress",
//     "Joomla",
//     "HTML",
//     "Pack office",
//     "MailChimp",
//     "Prospection",
//   ],
//   languages: ["Anglais", "Espagnol", "Polonais"],
// };
