import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { WorkType } from "../../../@types/workType";
import { modalStyle } from "../../../_utils/modalStyle";
import ControlledLocationAutocomplete from "../../common/autocomplete/ControlledLocationAutocomplete";
import ControlledWorkAutocomplete from "../../common/autocomplete/ControlledWorkAutocomplete";
import ControlledMonthSelector from "../../forms/ControlledMonthSelector";
import ControlledYearSelector from "../../forms/ControlledYearSelector";
import ControlledQuill from "../../forms/ControlledQuill";
import projectsService from "../../../services/projects.service";
import CustomButtonModal from "../../common/CustomBtnModal";
import { toastError } from "../../../_utils/toastError";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface IFormInput {
  month: number | any;
  year: number | any;
  work: WorkType | any;
  comments: string;
  location: any;
}

const ProjectModal: React.FC<any> = ({ open, close, project }) => {
  const context = useSelector((state: any) => state.auth.context);
  const { t } = useTranslation("common");
  const { personId, id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      month: project?.month ?? null,
      year: project?.year ?? null,
      work: project?.work ?? null,
      comments: project?.comments ?? "",
      location: project?.location ?? null,
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    setLoading(true);
    const body = {
      year: data.year,
      comments: data.comments,
      location: data.location,
      month: data.month,
      work: data.work?.["@id"],
    };

    if (project?.id) {
      projectsService.edit(project.id, body).subscribe({
        next: () => {
          close();
        },
        error: () => {
          setLoading(false);
        },
      });
    } else {
      console.log("CREATE");
      if (personId || id) {
        projectsService
          .createProjectPerson(body, personId ? personId : id)
          .subscribe({
            next: () => {
              toast.success(t("projects.created"));
              close();
            },
            error: (err) => {
              toastError();
              setLoading(false);
            },
          });
      } else {
        projectsService.createProject(body).subscribe({
          next: () => {
            toast.success(t("projects.created"));
            close();
          },
          error: (err) => {
            toastError();
            setLoading(false);
          },
        });
      }
    }
  };

  const handleWork = (value: any) => {
    setValue("work", value);
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "80%", md: "650px" },
        }}
      >
        <CardContent sx={{ pt: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h5">{t("projects.add_project")}</Typography>
            <IconButton onClick={close} sx={{ ml: "auto" }} aria-label="close">
              <HighlightOff />
            </IconButton>
          </Box>

          <Box>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Stack spacing={2}>
                {context !== "benef" && context !== "advisor" && (
                  <div>
                    <Typography marginBottom={0.5}>
                      {t("projects.date")}
                    </Typography>

                    <Box display={"flex"}>
                      <Box paddingRight={1} flex={"0 0 50%"}>
                        <ControlledMonthSelector
                          name={"month"}
                          control={control}
                          label={t("projects.month")}
                          required
                        />
                        {Boolean(errors?.["month"]) && (
                          <Typography
                            variant="body2"
                            component="small"
                            color="error"
                          >
                            {errors["month"]
                              ? t(String(errors["month"]?.message) ?? "")
                              : " "}
                          </Typography>
                        )}
                      </Box>

                      <Box paddingLeft={1} flex={"0 0 50%"}>
                        <ControlledYearSelector
                          name={"year"}
                          control={control}
                          label={t("projects.year")}
                          required
                        />
                        {Boolean(errors?.["year"]) && (
                          <Typography
                            variant="body2"
                            component="small"
                            color="error"
                          >
                            {errors["year"]
                              ? t(String(errors["year"]?.message) ?? "")
                              : " "}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </div>
                )}

                <ControlledWorkAutocomplete
                  label={`${t("projects.job_wanted")} *`}
                  clearErrors={clearErrors}
                  control={control}
                  required
                  workObject={getValues("work")}
                  handleWork={handleWork}
                />

                <ControlledLocationAutocomplete
                  control={control}
                  label={t("projects.workplace_wanted")}
                />

                <div>
                  <Typography sx={{ mb: 0.5 }}>
                    {t("projects.motivations")}
                  </Typography>
                  <ControlledQuill
                    noMargin
                    name="comments"
                    placeholder={t("admin.params.skills.description")}
                    control={control}
                  />
                </div>

                <div>
                  <Typography>{t("projects.required_fields")}</Typography>

                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="center"
                    sx={{ mt: 1 }}
                  >
                    <CustomButtonModal
                      disabled={loading}
                      type="submit"
                      variant="contained"
                      text={t("btn.save")}
                    />
                    <CustomButtonModal
                      secondary
                      onClick={close}
                      variant="outlined"
                      color="secondary"
                      text={t(`btn.cancel`)}
                    />
                  </Box>
                </div>
              </Stack>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ProjectModal;
